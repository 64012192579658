(window.__NEXT_P  =  window.__NEXT_P  ||  []).push([
"/",

      function  ()  {

        return  require("private-next-pages/index.js");

      }

    ]);

    if(module.hot)  {

      module.hot.dispose(function  ()  {

        window.__NEXT_P.push(["/"])

      });

    }