import  Link  from 'next/link';

import  React  from 'react'

import  Slider  from 'react-slick';

import  config  from '../../../config';

function  latestTrending(props)  {

    console.log("98989",  props)

    var  settings  =  {

        dots:  true,

        infinite:  false,

        speed:  500,

        slidesToShow:  1,

        slidesToScroll:  1,

        centerMode:  true,

        centerPadding: '50px',

        dots:  false,

        arrows:  false,

        
      };

      return  (

        <Slider  {...settings}>

          <div>

            <div  className='new-collection-bx'>

            <a  href={`${config.HEADLESS_BASE_URL}/tiles/anti-skid-tiles`}>

                <img  src="/ANTI SKID TILES.jpg"   alt='एंटी स्किड टाइल्स'/>

                <h6>ANTI  SKID  TILES</h6>

                </a>

            </div>

          </div>

          <div>

          <  div  className='new-collection-bx'>

          <a  href={`${config.HEADLESS_BASE_URL}/tiles/cool-tiles`}>

                <img  src="COOL ROOF TILES.jpg"   alt='कूल रूफ टाइल्स'/>

                <h6>COOL  ROOF  TILES</h6>

                </a>

            </div>

            
          </div>



          <div>

          <  div  className='new-collection-bx'>

          <a  href={`${config.HEADLESS_BASE_URL}/tiles/elevation-tiles`}>

                <img  src="ELEVATION TILES.jpg"   alt='एलिवेशन टाइल्स'/>

                <h6>ELEVATION  TILES</h6>

                </a>

            </div>

            
          </div>


          {/* <div>
          < div className='new-collection-bx'>
                <img src="HEAVY LOAD TILES.jpg"  alt='HEAVY LOAD TILES'/>
                <h6><a href="#">HEAVY LOAD TILES</a></h6>
            </div>
            
          </div> */}


          <div>

          <  div  className='new-collection-bx'>

          <a  href={`${config.HEADLESS_BASE_URL}/tiles/highlighter-tiles`}>

                <img  src="/HIGHLIGHTER TILES.jpg"   alt='हाईलाइटर टाइल्स'/>

                <h6>HIGHLIGHTER  TILES</h6></a>

            </div>

            
          </div>


          <div>

          <  div  className='new-collection-bx'>

          <a  href={`${config.HEADLESS_BASE_URL}/tiles/forever-tiles`}>

                <img  src="/SCRATCH FREE TILES.jpg"   alt='फ्री टाइल्स स्क्रैच करें'/>

                <h6>FOREVER  TILES</h6></a>

            </div>

            
          </div>

          <div>

          <  div  className='new-collection-bx'>

          <a  href={`${config.HEADLESS_BASE_URL}/tiles/large-tiles`}>

                <img  src="/LARGE SLAB TILES.jpg"   alt='बड़ी स्लैब टाइल्स'/>

                <h6>LARGE  SLAB  TILES</h6></a>

            </div>

            
          </div>

          <div>

          <  div  className='new-collection-bx'>

          <a  href={`${config.HEADLESS_BASE_URL}/tiles/parking-tiles`}>

                <img  src="/PARKING TILES.jpg"   alt='पार्किंग टाइल्स'/>

                <h6>PARKING  TILES</h6></a>

            </div>

            
          </div>


          <div>

          <  div  className='new-collection-bx'>

          <a  href={`${config.HEADLESS_BASE_URL}/tiles/wall-tiles`}>

                <img  src="/WALL TILES1.jpg"   alt='दीवार की टाइल'/>

                <h6>WALL  TILES</h6></a>

            </div>

            
          </div>



          <  div  className='new-collection-bx'>

               <Link  href={`${config.HEADLESS_BASE_URL}/tiles`}>  <img  src="/view-all-tiles-black.jpg"   alt='view all tiles image'/></Link>

             
            </div>

        </Slider>

      );

}


export  default  latestTrending