import  Link  from 'next/link';

import  React  from 'react'

import  Slider  from 'react-slick';

import  config  from '../../../config';

function  largeCollectionMobileSlider(props)  {

    console.log("98989",  props)

    var  settings  =  {

        dots:  true,

        infinite:  false,

        speed:  500,

        slidesToShow:  1,

        slidesToScroll:  1,

        centerMode:  true,

        centerPadding: '50px',

        dots:  false,

        arrows:  false,

        
      };

      return  (

        <Slider  {...settings}>

         
          {/* <div>
          < div className='new-collection-bx'>
          <a href={`${config.HEADLESS_BASE_URL}/pgvt-armani-marble-grey-dk`}>
                <img src="/PGVT Armani Marble Grey DK_800x1600mm3.jpg"  alt='PGVT Armani Marble Grey DK_800x1600mm'/>
                <h6>PGVT Armani Marble Grey DK<br/><span>800X1600 mm</span></h6></a>
            </div>
           
            
          </div>
        
          <div>
            <div className='new-collection-bx'>
            <a href={`${config.HEADLESS_BASE_URL}/pgvt-endless-sofita-beige`}>
                <img src="/PGVT Endless Sofita Beige_800x1600mm2.jpg" alt='public/PGVT Endless Sofita Beige_800x1600mm'/>
                <h6>PGVT Endless Sofita Beige <br/><span>800X1600 mm</span></h6></a>
            </div>
          </div> */}


          <div>

            <div  className='new-collection-bx'>

            <a  href={`${config.HEADLESS_BASE_URL}/pgvt-calacatta-bellissimo-marmi`}>

                <img  src="/PGVT Calacatta Bellissimo Marmi - 800x1600.jpg"  alt='PGVT Calacatta Bellissimo Marmi_800x1600 '/>

                <h6>PGVT  Calacatta  Bellissimo  Marmi<br/><span>800X1600 mm</span></h6></a>
            </div>
          </div>

          <div>
            <div className='new-collection-bx'>
            <a href={`${config.HEADLESS_BASE_URL}/pgvt-onyx-pearl`}>
                <img src="/PGVT ONYX PEARL - 800x1600.jpg" alt='PGVT ONYX PEARL '/>
                <h6>PGVT ONYX PEARL<br/><span>800X1600 mm</span></h6></a>
            </div>
          </div>

          <div>
            <div className='new-collection-bx'>
            <a href={`${config.HEADLESS_BASE_URL}/carving-sofita-marble-beige`}>
                <img src="/Carving Sofita Marble Beige - 800x1600.jpg" alt='Carving Sofita Marble Beige - 800x1600 '/>
                <h6>Carving Sofita Marble Beige<br/><span>800X1600 mm</span></h6></a>
            </div>
          </div>

          <div>
            <div className='new-collection-bx'>
            <a href={`${config.HEADLESS_BASE_URL}/granalt-snp-royal-black`}>
                <img src="/Granalt SNP Black - 800x2400.jpg" alt='Granalt SNP Black - 800x2400 mm'/>
                <h6>Granalt SNP Black<br/><span>800X2400 mm</span></h6></a>
            </div>
          </div>



          <div>
            <div className='new-collection-bx'>
            <a href={`${config.HEADLESS_BASE_URL}/granalt-statuario`}>
                <img src="/Granalt Statuario - 800x2400.jpg" alt='Granalt Statuario - 800x2400'/>
                <h6>Granalt Statuario<br/><span>800X2400 mm</span></h6></a>
            </div>
          </div>



          <div>
            <div className='new-collection-bx'>
            <a href={`${config.HEADLESS_BASE_URL}/granalt-galactic-blue`}>
                <img src="/Granalt Galactic Blue - 800x2400.jpg" alt='Granalt Galactic Blue - 800x2400'/>
                <h6>Granalt Galactic Blue<br/><span>800X2400 mm</span></h6></a>
            </div>
          </div>



          <div>
            <div className='new-collection-bx'>
            <a href={`${config.HEADLESS_BASE_URL}/pgvt-endless-statuario-marble`}>
                <img src="/PGVT Endless Statuario Marble - 1200x1800.jpg" alt='PGVT Endless Statuario Marble - 1200x1800 '/>
                <h6>PGVT Endless Statuario Marble <br/><span>1200X1800 mm</span></h6></a>
            </div>
          </div>


          <div>
            <div className='new-collection-bx'>
            <a href={`${config.HEADLESS_BASE_URL}/silken-piasentina-stone-grey`}>
                <img src="/Silken Piasentina Stone Grey - 1200x1800.jpg" alt='Silken Piasentina Stone Grey'/>
                <h6>Silken Piasentina Stone Grey<br/><span>1200X1800 mm</span></h6></a>
            </div>
          </div>


          <div>
            <div className='new-collection-bx'>
            <a href={`${config.HEADLESS_BASE_URL}/super-gloss-jeriba-quartzite-blue`}>
                <img src="/Super Gloss Jeriba Quartzite Blue - 1200x1800.jpg" alt='Super Gloss Jeriba Quartzite Blue - 1200x1800'/>
                <h6>Super Gloss Jeriba Quartzite Blue<br/><span>1200X1800 mm</span></h6></a>
            </div>
          </div>

          <div>
          < div className='new-collection-bx'>
               <Link href={`${config.HEADLESS_BASE_URL}/tiles/large-tiles`}> <img src="/view-all-tiles-black.jpg"  alt='view all tiles image'/></Link>
              
            </div>
            
          </div>


          
        </Slider>
      );
}

export default largeCollectionMobileSlider